<template>
  <h3>
    <small v-if="story.primary_category">
      {{ story.primary_category.label }}
    </small>
    <br />
    <span
      :class="{
        'badge-success': story.status == 'PUBLISHED',
        'badge-danger': story.status == 'DISABLED',
      }"
      class="badge m-l-5 m-r-5 mr-1"
    >
      {{ $t(`STORY.${story.status}`) }}
    </span>
    <span
      v-if="story.scope === 'PRIVATE'"
      class="badge badge-danger m-l-5 m-r-5"
    >
      <font-awesome-icon icon="eye-slash" class="mr-1" />
    </span>
    <span v-if="story.scheme === 'STORY'">
      <font-awesome-icon icon="newspaper" class="mr-1" />
    </span>
    <span v-if="story.scheme === 'VIDEO'" class="mr-1">
      <font-awesome-icon icon="tv" />
    </span>
    <span v-if="story.rich_title" v-html="story.rich_title"></span>
    <span v-else>{{ story.title || 'Aucun titre' }}</span>
    <span v-if="story.authors && story.authors.length > 0">
      par
      {{
        story.authors
          .map((t) => {
            return t
          })
          .join(', ')
      }}
    </span>
  </h3>
</template>

<script>
export default {
  name: 'StoryCard',
  components: {},
  props: {
    story: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>
