<template>
  <div>
    <b-card v-if="story.id">
      <div class="row">
        <div class="col-lg-7">
          <story-title :story="story" />
        </div>
        <div class="col-lg-5 text-right">
          <router-link
            :to="`/stories/${story.id}`"
            class="btn btn-secondary btn-sm mr-1"
          >
            Retour
          </router-link>
          <a
            :href="story.canonical_url"
            target="_blank"
            class="btn btn-info btn-sm mr-1"
          >
            <font-awesome-icon icon="eye" />
            Voir la page
          </a>
          <button class="btn btn-sm btn-primary" @click.prevent="save()">
            Enregister
          </button>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-lg-8">
          <flux-editor
            v-if="story.id"
            ref="fluxEditor"
            :contents.sync="story.content_blocks"
            :attachments.sync="story.attachments"
            class="story-content"
          />
        </div>
        <div class="sticky-top" style="top: 70px">
          <story-editor-attachments
            :story="story"
            @uploaded="attachmentEvent"
            @deleted="attachmentEvent"
          />
        </div>
      </div>
    </b-card>
    <pre>{{ story }}</pre>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import StoryTitle from '@/components/StoryTitle.vue'
import StoryEditorAttachments from '@/components/StoryEditorAttachments.vue'

const storyFields =
  'id,title,rich_title,headline,extract,content_with_post_rendering,status,canonical_url,scheme,' +
  'content_blocks,cover_image_credits,cover_image_url,thumb_hero_url,' +
  'scope,primary_category{id,label},created_at,updated_at,thumbnails_formats,' +
  'content_type,slug,comments_allowed,published_at,is_published,' +
  'authors{id,screenname},' +
  'attachments,attachments_by_keys,attributes,' +
  'publication_rules,' +
  'story_tags{id,label},contextual_stories{id,title},contextual_carousel{id,title},' +
  'story_categories{id,label},' +
  'related_stories{id,title,thumb_story_list_url,primary_category{label},created_at,published_at}'

export default {
  components: {
    BCard,
    StoryTitle,
    StoryEditorAttachments,
  },
  data() {
    return {
      editCover: false,
      story: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/stories/${this.$route.params.id}/`, {
        headers: {
          'X-Fields': storyFields,
        },
      })
      this.story = resp.data
    },
    async save() {
      const content = this.$refs.fluxEditor.getContent()
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        await this.$http.put(
          `/stories/${this.$route.params.id}/`,
          { content_blocks: content },
          {
            headers: {
              'X-UpdateKeys': 'content_blocks',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    attachmentEvent(story) {
      this.story.attachments = story.attachments
    },
  },
}
</script>
