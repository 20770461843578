<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <b-form-group>
          <b-form-file
            v-model="attachment"
            :state="Boolean(attachment)"
            browse-text="Sélectionner"
            placeholder="Associer un fichier"
          />
          <p v-if="attachment" class="text-center mt-3 mb-5">
            <b-button class="btn btn-primary" @click.prevent="uploadFile()">
              Transmettre
            </b-button>
            <span v-if="onUploadProgress > 0" class="mr-2">
              {{ onUploadProgress }}%
            </span>
          </p>
        </b-form-group>
      </div>
    </div>
    <div
      class="border-bottom border-3"
      style="max-height: 625px; overflow: scroll"
    >
      <table class="table">
        <tbody v-for="item in story.attachments" :key="item.id">
          <tr v-if="item.key == 'story_attachment'">
            <td class="px-0">
              <copy-value
                :value="item.static_url"
                :display="item.meta.filename"
                class="text-wrap"
              />
            </td>
            <td class="px-0">
              <div class="text-right text-nowrap">
                <a
                  v-b-modal.modal-delete
                  href
                  @click.prevent="deleteAttachmentId = item.id"
                >
                  <font-awesome-icon icon="trash" />
                </a>
                <a
                  v-if="item.meta.type == 'audio'"
                  :href="item.static_url"
                  target="_blank"
                  class="pl-2"
                >
                  <font-awesome-icon icon="file-audio" />
                </a>
                <a v-else :href="item.static_url" target="_blank" class="pl-2">
                  <font-awesome-icon icon="eye" />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="text-right text-muted pt-2 mb-0">
      {{ story.attachments.length }} fichiers associés
    </p>
    <b-modal
      id="modal-delete"
      ref="delete-attachment"
      centered
      hide-header-close
      title="Suprimmer le fichier"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button
          size="sm"
          variant="danger"
          @click="deleteAttachment(deleteAttachmentId)"
        >
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ story.attachments }}</pre> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BFormGroup, BButton, BFormFile } from 'bootstrap-vue'

export default {
  components: { BFormGroup, BFormFile, BButton },
  props: {
    story: {
      type: Object,
      default() {
        return {}
      },
    },
    display: {
      type: Boolean,
      default: false,
    },
    afterUpdate: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      attachment: null,
      deleteAttachmentId: null,
      uploadPercentage: 0,
      onUploadProgress: 0,
    }
  },
  computed: {
    ...mapState({
      constants: (state) => state.core.constants,
    }),
  },
  watch: {
    attachment(value) {
      if (value) {
        this.uploadFile()
      }
    },
  },
  methods: {
    async uploadFile() {
      const formData = new FormData()
      formData.append('attachment', this.attachment)
      // console.log(formData)
      // console.log(this.documents[key])
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/stories/${this.story.id}/upload-attachment/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
            'X-Fields': 'id,attachments',
          },
          onUploadProgress(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100),
              10
            )
          },
        }
      )
      this.onUploadProgress = 0
      this.attachment = null
      this.$emit('uploaded', resp.data)
      this.$forceUpdate()
    },
    async deleteAttachment(attachmentId) {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(
        `/stories/${this.story.id}/attachments/${attachmentId}/`,
        {
          headers: {
            'X-Fields': 'id,attachments',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$forceUpdate()
      this.$emit('deleted', resp.data)
      this.$refs['delete-attachment'].hide()
    },
  },
}
</script>
